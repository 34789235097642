<!--
* @description:
* @fileName other.vue
* @author hvv
* @date 2022/02/28 10:53:32
!-->
<template>
  <div></div>
</template>

<script>
  import {
    defineComponent,
    getCurrentInstance,
    onMounted,
    reactive,
    toRefs,
  } from 'vue'
  export default defineComponent({
    name: 'other.vue',
    setup() {},
  })
</script>
<style lang="scss" scoped></style>
